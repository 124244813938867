@use '../includes' as *;

$popover-bg-color: $charcoal;

.kabob_menu_button {
  @include icon-button;

  @include breakpoint($sm) {
    border-width: 2px;
  }
}

.kabob_menu_arrow {
  fill: rgba($popover-bg-color, 0.9);

  // stylelint-disable-next-line
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.kabob_menu_content {
  @include bg-blur($popover-bg-color);

  position: relative;
  z-index: 11;
  min-width: 225px;
  max-width: 350px;
  padding: $g24 $g16;
  border-radius: 6px;
  color: $fog;
}

.kabob_menu_content_inner {
  font-size: clamp($f14, 0.9077rem + 0.4808vw, $f16);

  ul {
    display: flex;
    flex-direction: column;
    gap: $g16;
  }

  li {
    display: flex;
    align-items: baseline;
  }

  svg {
    height: 13px;
    margin-inline-end: $g8;
    fill: $white;
  }
}

.kabob_menu_close_button {
  position: absolute;
  inset: $g12 $g12 auto auto;
  padding: 10px;
  border: unset;
  cursor: pointer;

  svg {
    width: 20px;
    max-width: unset;
    height: 20px;
    max-height: unset;
    margin: unset;
  }
}
