@use '../includes' as *;

.watch_video_button {
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gutter);
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:focus-visible {
    // this is usually bad practice, but we're adding the outline
    // to the icon div below
    outline: none;
  }
}

.vertical_offset {
  position: relative;
  inset-block-start: -10%;
}

.watch_video_button_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(50px, 13cqw, 100px);
  height: clamp(50px, 13cqw, 100px);
  transition-property: background-color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in;
  border-radius: 50%;
  background-color: rgba($midnight, 0.5);

  // stylelint-disable-next-line
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  svg {
    width: clamp(16.5px, 4.29cqw, 33px);
    height: clamp(21px, 5.46cqw, 42px);
    fill: $white;
    translate: 3px 0;
  }

  .watch_video_button:hover & {
    background-color: rgba($shadow, 0.5);
  }

  .watch_video_button:focus-visible & {
    outline: 3px solid $light-blue;
  }

  .watch_video_button[disabled] & {
    background-color: rgba($pebble, 0.5);
  }
}

.watch_video_button_text {
  @include h5($white);

  text-shadow: 0 0 12px rgb(0 5 37 / 80%);
}
