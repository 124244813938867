@use '../includes' as *;

.mezzanine_video_initializer {
  display: flex;
  container-type: inline-size;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  background-color: $midnight;

  img {
    position: absolute;
    inset: 0;
    z-index: 0;
    width: 100%;
  }
}
